var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 pl-4 pb-2 mb-4"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('p',{staticClass:"display-1 text--primary",domProps:{"innerHTML":_vm._s(_vm.$t('cronQueue.heading'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$tc('cronQueue.subheading', _vm.jobs.length))}})]),_c('v-row',[_c('v-col',{staticClass:"pl-6 pr-6"},[_c('search',{ref:"searchComponent",attrs:{"value":_vm.search},on:{"input":function($event){_vm.search = $event}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-2 ma-3 cursor-pointer",attrs:{"headers":_vm.headers,"items":_vm.jobs,"search":_vm.search,"loading":_vm.$store.getters.getLoaders.jobs,"loading-text":_vm.$t('common.datatable.loading'),"no-data-text":_vm.$t('common.datatable.noData'),"no-results-text":_vm.$t('common.datatable.noResults'),"items-per-page":25,"footer-props":{
                      'items-per-page-options': [25, 50, 100, -1],
                      'items-per-page-text': _vm.$t('common.datatable.rowsPerPage'),
                      'items-per-page-all-text': _vm.$t('common.words.all')
                  }},scopedSlots:_vm._u([{key:"item.cronqueue_ssid",fn:function(ref){
                  var item = ref.item;
return [(item.cronqueue_ssid)?_c('v-avatar',{attrs:{"tile":"","size":"35"}},[_c('v-img',{attrs:{"contain":"","src":require(("../assets/images/portale/" + (item.cronqueue_ssid) + ".png")),"lazy-src":require(("../assets/images/portale/" + (item.cronqueue_ssid) + ".png")),"alt":"Channel-Logo"}})],1):_c('v-avatar',{attrs:{"size":"35"}},[_c('v-icon',{attrs:{"color":_vm.$store.getters.getColors.secondary1}},[_vm._v(" mdi-image-area ")])],1)]}},{key:"item.cronqueue_tstamp",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getElapsedTime(parseInt(item.cronqueue_tstamp)))+" ")]}},{key:"item.cronqueue_status",fn:function(ref){
                  var item = ref.item;
return [(item.cronqueue_status === 1)?_c('v-progress-circular',{attrs:{"size":15,"indeterminate":"","color":_vm.$store.getters.getColors.success}}):_c('v-icon',{attrs:{"x-small":"","color":_vm.$store.getters.getColors.warning}},[_vm._v(" mdi-checkbox-blank-circle ")])]}},{key:"item.cronqueue_cycles",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getCycleByChannel(parseInt(item.cronqueue_ssid))))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }