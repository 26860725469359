<template>
    <div class="w-100 pl-4 pb-2 mb-4">
        <v-card outlined>
            <v-card-text>
                <p class="display-1 text--primary" v-html="$t('cronQueue.heading')"></p>
                <p v-html="$tc('cronQueue.subheading', jobs.length)"></p>
            </v-card-text>
            <v-row>
                <v-col class="pl-6 pr-6">
                    <search
                        :value="search"
                        ref="searchComponent"
                        @input="search = $event"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="jobs"
                        :search="search"
                        :loading="$store.getters.getLoaders.jobs"
                        :loading-text="$t('common.datatable.loading')"
                        :no-data-text="$t('common.datatable.noData')"
                        :no-results-text="$t('common.datatable.noResults')"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                        class="elevation-2 ma-3 cursor-pointer"
                    >
                        <template v-slot:item.cronqueue_ssid="{ item }">
                            <v-avatar
                                tile
                                v-if="item.cronqueue_ssid"
                                size="35"
                            >
                                <v-img
                                    contain
                                    :src="require(`../assets/images/portale/${item.cronqueue_ssid}.png`)"
                                    :lazy-src="require(`../assets/images/portale/${item.cronqueue_ssid}.png`)"
                                    alt="Channel-Logo"
                                />
                            </v-avatar>
                            <v-avatar
                                v-else
                                size="35"
                            >
                                <v-icon :color="$store.getters.getColors.secondary1">
                                    mdi-image-area
                                </v-icon>
                            </v-avatar>
                        </template>
                        <template v-slot:item.cronqueue_tstamp="{ item }">
                            {{ getElapsedTime(parseInt(item.cronqueue_tstamp)) }}
                        </template>
                        <template v-slot:item.cronqueue_status="{ item }">
                            <v-progress-circular
                                v-if="item.cronqueue_status === 1"
                                :size="15"
                                indeterminate
                                :color="$store.getters.getColors.success"
                            />
                            <v-icon v-else x-small :color="$store.getters.getColors.warning">
                                mdi-checkbox-blank-circle
                            </v-icon>
                        </template>
                        <template v-slot:item.cronqueue_cycles="{ item }">
                            <span>{{ getCycleByChannel(parseInt(item.cronqueue_ssid)) }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Search from '@/components/Allgemein/Suche.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import QueueService from '@/services/queue.service'

const name = 'CronQueueJobs'

export default {
    name: name,
    components: { Search },
    metaInfo: {
        title: 'Cron queue Jobs',
    },
    sockets: {
        getCronQueueJobs(data) {
            if (data.hasOwnProperty('cronqueuejobs'))
                this.jobs = data.cronqueuejobs
        },
    },
    data() {
        return {
            name: name,
            expanded: [],
            singleExpand: false,
            search: '',
            jobs: [],
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getLocale',
        ]),
        headers: function () {
            return [
                {
                    text: '',
                    value: 'cronqueue_ssid',
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: '25px',
                },
                {
                    text: this.$i18n.t('common.labels.createdAt'),
                    value: 'cronqueue_tstamp',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.ids.memberid'),
                    value: 'cronqueue_memberid',
                    align: 'end',
                    divider: true,
                    width: '130px',
                },
                {
                    text: this.$i18n.t('common.labels.ids.propertyid'),
                    value: 'cronqueue_objid',
                    align: 'end',
                    divider: true,
                    width: '130px',
                },
                {
                    text: this.$i18n.t('common.words.job'),
                    value: 'cronqueue_job',
                    divider: true,
                    width: '150px',
                },
                {
                    text: this.$i18n.t('common.labels.status'),
                    value: 'cronqueue_status',
                    align: 'center',
                    divider: true,
                    width: '90px',
                },
                {
                    text: this.$i18n.t('common.words.cycles'),
                    value: 'cronqueue_cycles',
                    sortable: false,
                    divider: true,
                    width: '120px',
                },
            ]
        },
    },
    methods: {
        getCycleByChannel(ssid) {
            let cycles = []

            moment.locale(this.getLocale)

            switch (ssid) {
                case 31:
                    cycles = [
                        moment(new Date(0, 0, 0, 8, 0)).format('H:mm'),
                        moment(new Date(0, 0, 0, 20, 0)).format('H:mm'),
                    ]
            }

            return `At ${ cycles.join(', ') }`
        },
        getImage(ssid) {

            if (ssid)
                return `../assets/images/portale/${ ssid }.png`

            return ''
        },
        getElapsedTime(timestamp) {
            moment.locale(this.getLocale)
            return moment.unix(timestamp).fromNow()
        },
    },
    async created() {
        await this.$store.dispatch('setLoaders', { jobs: true })
        this.jobs = await QueueService.getCronQueueJobs()
        await this.$store.dispatch('setLoaders', { jobs: false })
    },
}
</script>
