import communicate from '@/services/communicate.service'

export default {
    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getAllJobs () {
        return await communicate.request(enums.GET, 'jobs')
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async deleteJob (id) {
        return await communicate.request(enums.DELETE, `jobs/${ id }`)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async resetJob (id) {
        return await communicate.request(enums.POST, `jobs/${ id }/reset`)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getCronQueueJobs () {
        return await communicate.request(enums.GET, 'cronqueuejobs')
    },

    /**
     *
     * @param payload
     * @returns {Promise<*|{warning}|{body}>}
     */
    async setJobCreation (payload) {
        return await communicate.request(enums.POST, 'setjobcreation', payload)
    },
}